



































import Vue from 'vue';
import { ApiResource } from '@/types';
import InvoiceVoucherPdfDownloadButton from '@/components/dashboard/InvoiceVoucherPdfDownloadButton.vue';

export default Vue.extend({
  components: {
    InvoiceVoucherPdfDownloadButton,
  },
  props: {
    voucher: {
      type: Object as () => ApiResource.Business.InvoiceVoucher,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {

  },
});
