
































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import MediaInvoiceVoucher from '@/components/media/InvoiceVoucher.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
  listVouchers: (id: string) => ApiResource.Business.InvoiceVoucher[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    MediaInvoiceVoucher,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as Error | null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
      listVouchers: 'business/Vouchers/List',
    }),
    account(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.business.id);
    },
    list(): ApiResource.Business.InvoiceVoucher[] {
      if (!this.account) return [];
      return this.listVouchers(this.account.id)
        .sort((
          a: ApiResource.Business.InvoiceVoucher,
          b: ApiResource.Business.InvoiceVoucher,
        ) => b.created_at - a.created_at);
    },
    business(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
  },
  watch: {
    'business.id': {
      immediate: true,
      handler(to) {
        if (to) this.fetchVouchers();
      },
    },
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    async fetchVouchers() {
      this.loading = true;

      await this.$store.dispatch('business/Vouchers/List', {
        BUSINESS_ID: this.business.id,
        query: {
          limit: 10,
          direction: 'desc',
          order: 'created_at',
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
